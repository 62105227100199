import { Component, Vue, Watch } from 'vue-property-decorator';
import { SidemenuItem } from '@/components/sidemenu/Sidemenu';
import { getStatusLabel, ReportStatus } from '@/support/ReportStatus';
import { UserRole } from '@/models/User';

@Component<CaseMediators>({})
export default class CaseMediators extends Vue {
  public $pageTitle = 'Zaakbegeleiders';

  protected isLoading = true;

  protected items: SidemenuItem[] = [
    {
      title: 'Dashboard Zelfopname',
      link: '/zaakbegeleiding',
      icon: {
        key: 'dashboard',
      },
      visible: this.$store.state.Auth.hasRole([UserRole.ADMIN, UserRole.MANAGER]),
    },
    {
      title: getStatusLabel(ReportStatus.ZAAKBEGELEIDER_INCOMING),
      link: `/zaakbegeleiding/reports?status=${ReportStatus.ZAAKBEGELEIDER_INCOMING}`,
      icon: {
        key: 'assignment_ind',
      },
    },
    {
      title: getStatusLabel(ReportStatus.ZAAKBEGELEIDER),
      link: `/zaakbegeleiding/reports?status=${ReportStatus.ZAAKBEGELEIDER}`,
      icon: {
        key: 'assignment_ind',
      },
    },
    {
      title: 'Rapportage Zaakbegeleiding',
      link: '/zaakbegeleiding/rapportage',
      icon: { key: 'show_chart' },
    },
  ];

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    this.emitBreadcrumb();
    if (! this.$store.state.Auth.hasRole([UserRole.ADMIN, UserRole.MANAGER])) {
      this.$router.push(`/zaakbegeleiding/reports?status=${ReportStatus.ZAAKBEGELEIDER_INCOMING}`);
    }
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'Zaakbegeleiders' }],
    });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
